import React, { useState, useEffect } from 'react'
import COLORS from '../resources/colors'

export default function Documentation() { 
    
    return <div className='PageRoot'>

        <div className='SidebarContainer'>
            {/* <div className="Sidebar">
                <div className='LogoContainer'>

                </div>
            </div> */}
        </div>
        {/* <div className='Navbar'>

        </div>
        <div className='Content'>

        </div> */}

        <style global jsx="true">{`
            body { 
                font-family: "Helvetica Neue", "Arial";   
                margin: 0px;
                padding: 0px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box; 
            }
        `}</style>


        <style jsx="true">{`
                
            --doc-sidebar-width: 300px;
            --doc-navbar-height: 100px;

            .PageRoot { 
                width: 100vw;
                min-height: 100vh;
                display: grid; 
                grid-template-rows: var(--doc-navbar-height) 1fr;
                grid-template-columns: var(--doc-sidebar-width) 1fr;
            }

            .SidebarContainer { 
                grid-row: 0 / span 2;
                grid-column: 0;
                border-right: 1px solid ${COLORS.offWhiteColor};
                -webkit-clip-path: inset(0);
                clip-path: inset(0);
                display: block;
                margin-top: 0;
                transition: width 225 ease;
                var(--doc-sidebar-width);
                height: 100vh;
                will-change: width;
                z-index: 2;
                bacground: blue;
            }

            .Sidebar { 
                background: #000;
                display: flex;
                flex-direction: column;
                height: 100%;
                max-height: 100vh;
                top: 0;
                transition: opacity 50ms;
                width: var(--doc-sidebar-width);
            }

            .LogoContainer { 
                background-color: red;
                width: 100%;
                height: var(--doc-navbar-height);
            }

            .Content { 
                grid-row: 1;
                grid-column: 1;
                display: grid;
                background: blue;
            }

            .Navbar { 
                grid-row: 0;
                grid-column: 0;
                background-color: red;
                width: 100%;
                height: var(--doc-navbar-height);
            }

        `}</style>
    </div>
}